const roundToThousandth = num => Math.round(num*1000)/1000;

const getMouseCoordsOnTarget = (e) => {
  let currentTargetRect = e.currentTarget.getBoundingClientRect();
  const x = roundToThousandth((e.pageX - currentTargetRect.left) / currentTargetRect.width);
  const y = roundToThousandth((e.pageY - currentTargetRect.top) / currentTargetRect.height);

  return {x, y};
};

export default getMouseCoordsOnTarget;
