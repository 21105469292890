import React, {useState} from "react";
import ImportedImageForLinking from "./ImportedImageForLinking";
import SimpleLinkedImage from "./SimpleLinkedImage";
import useKonami from 'use-konami';

const LinkedImage = ({name, src, alt, links, onTraversal}) => {
  const [isEditing, setIsEditing] = useState(false);

  useKonami({
    onUnlock: () => setIsEditing(true)
  })

  return isEditing
    ? <ImportedImageForLinking name={name} src={src} initialLinks={links} />
    : <SimpleLinkedImage src={src} alt={alt} links={links} onTraversal={onTraversal} />;
}

export default LinkedImage;
