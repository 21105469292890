import React from 'react';

import './PatientKnight.scss';
import labelTopSpacerSrc from "./assets/label-top-spacer-fs8.png";
import labelTopLeftSpacerSrc from "./assets/label-top-left-spacer-fs8.png";
import labelTopBackgroundSrc from "./assets/label-top-background-fs8.png";
import labelBottomSrc from "./assets/label-bottom-fs8.png";

/**
 * The brew label page for Green Apprentice.
 * @author esyvret Aug. 8, 2021
 */
const PatientKnight = () => (
  <div className={'PatientKnight brew'}>
    <div className={'brew-content'}>
      <img src={labelTopBackgroundSrc} alt={'Patient Knight'} className={'label-top-background full-width-block'} />
      <img src={labelTopSpacerSrc} alt={''} className={'label-top-spacer full-width-block'} />
      <div className={'brew-content-text'}>
        <img src={labelTopLeftSpacerSrc} alt={''} className={'label-top-left-spacer'} />
        <p>Ingredients - water, malted barley (2-row, black malt), flaked oats, Saazquatch hops, US-05 yeast, fresh Ontario blueberries, and cacao nibs.</p>
        <p>500 ml - 4.7% Beer</p>

        <p>Consume at your own risk. This home brew is brought to you by "Secret Algorithms Brewing", a gift of the universe's mysteries embodied. Feedback and questions are welcome and appreciated. Contact us at info.secretab@gmail.com</p>

        <p>It is very important that you rinse the bottles with just water and return to SA brewing.</p>

        <p>The Patient Knight waits for an opportunity to advance as foes strike recklessly, rushing to their timely demise. Champion of a soft bitter darkness abides.</p>
        <p>A dark ale endowed but not overburdened with chocolate and blueberry. Few special editions have been imbued with the tingling essence of habanero. "Bluebanero" flavour inspired by a spicy sweetened berry reduction. Even fewer editions have been anointed with the aura of green adept.</p>
      </div>
      <img src={labelBottomSrc} alt={''} className={'label-bottom-spacer full-width-block'} />
      <img src={labelBottomSrc} alt={''} className={'label-bottom full-width-block'} />
    </div>
  </div>
);

export default PatientKnight;
