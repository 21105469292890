import React from 'react';
import BREWS from "../brews/Brews";
import './BrewsPage.scss';

/**
 * A page that displays thumbnails and links for all the brew label pages.
 * @author esyvret Aug. 8, 2021
 */
const BrewsPage = ({setBrew}) => {

  return (
    <div className={'brews-page'}>
      <div className={'fullpage'}>
        <div className={'content'}>
          <div className={'thumbnails'}>
            {
              Object.values(BREWS).map(brew =>
                <img key={brew.key} src={brew.thumbnail} alt={brew.name} className={'thumbnail'} onClick={() => setBrew(brew.key)} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default BrewsPage;
