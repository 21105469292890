import SpeechOption from "./SpeechOption";
import SpeechNode from "./SpeechNode";

/**
 * A utility function that creates a sequence (linked list) of nodes which defines a section of dialogue in which one
 * character says a series of things to someone with a next option at all steps.
 *
 * Finishes with the given response options.
 *
 * @author esyvret 2021-09-04
 */
function createSpeechNodes(
  /** String - The name of the node sequence, they will be numbered `${name}0`, `${name}1`, etc. */
  name,

  /** TODO create a class for this - The portrait of whoever or whatever is talking (or the item being described in narration). */
  image,

  /** String[] - The speeches that are being said. They will each become a speech node. */
  [speech, ...rest],

  /** SpeechOption[] - The player's response options. Will be shown after the final speech. Should be of type SpeechOption. */
  options,

  /** number - The first number in the name sequence. Defaults to 0. */
  index = 0
) {
  return new SpeechNode(
    index ? `${name}${index}` : name,
    image,
    speech,
    rest.length > 0
      ? [new SpeechOption('>',createSpeechNodes(name, image, rest, options, index+1).name)]
      : options
  );
}

export default createSpeechNodes;
