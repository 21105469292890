import React, {useEffect, useRef, useState} from 'react';
import rhombus from './images/rhombus.svg';

import './Gallery.scss';
import StyledBackground from "../StyledBackground";
import ImageTile from "./ImageTile";

// Must stay synced with margin-scale-factor in ./Gallery.scss
const MARGIN_SCALE_FACTOR = 30;

const Gallery = ({srcSets, style}) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(300);
  const [tileWidth, setTileWidth] = useState(300);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);

          const tile = containerRef.current.childNodes[0];
          if (tile) {
            setTileWidth(tile.clientWidth);
          }
        }
      }
    )
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => observer.disconnect();
  });

  const marginInPx = tileWidth / MARGIN_SCALE_FACTOR;
  const firstRowTileCount = Math.floor(containerWidth / (tileWidth + marginInPx * 2));
  const secondRowTileCount = Math.floor(containerWidth / (tileWidth + marginInPx * 2) - 0.5);
  const cycleLength = firstRowTileCount + secondRowTileCount;
  const getSpacingClassNames = (sequencePosition) => {
    let className = '';
    if (secondRowTileCount > 0) {
      if (sequencePosition === firstRowTileCount) {
        className += ' left-spaced';
      }
      if (firstRowTileCount === secondRowTileCount) {
        if (sequencePosition === firstRowTileCount - 1) {
          className += ' right-spaced';
        }
      } else {
        if (sequencePosition === cycleLength - 1) {
          className += ' right-spaced';
        }
      }
    }
    return className;
  }

  const imageTiles = srcSets.map((srcSet, index) => (
    <ImageTile
      key={JSON.stringify(srcSet)}
      srcSet={srcSet}
      alt={''}
      marginScaleFactor={MARGIN_SCALE_FACTOR}
      className={`rhombus ${getSpacingClassNames(index % cycleLength)}`}
    />
  ));

  while (firstRowTileCount > 1 && imageTiles.length % cycleLength !== 0 && imageTiles.length % cycleLength !== firstRowTileCount) {
    imageTiles.push(
      <ImageTile
        key={imageTiles.length}
        src={rhombus}
        alt={'spacer'}
        marginScaleFactor={MARGIN_SCALE_FACTOR}
        className={`rhombus spacer ${getSpacingClassNames(imageTiles.length % cycleLength)}`}
      />
    )
  }


  return (
    <div className={'gallery'}>
      <div/>
      <StyledBackground src={'HomeBackground'} style={style} />
      <div className={'fullpage'}>
        <div ref={containerRef} className={'thumbnails'}>
          {imageTiles}
        </div>
      </div>
    </div>
  );
}

export default Gallery;
