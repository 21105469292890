const TopLevelPages = {
  HOME: "Home",
  // MY_STORY: "My Story",
  BREWS: "Brews",
  VOID: "The Void",
  GALLERY: "Gallery",
  GRAPH: "The Graph",
}

export default TopLevelPages;
