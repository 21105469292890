import React from "react";
import TopLevelPages from "./TopLevelPages";

import './TopNav.scss';

/** The top navigation bar that should be present on most pages. */
const TopNav = ({ setPage }) => (
  <div className={'topnav'}>
    {/*<button onClick={() => setPage(TopLevelPages.HOME)}>{TopLevelPages.HOME}</button>*/}
    <button onClick={() => setPage(TopLevelPages.GALLERY)}>{TopLevelPages.GALLERY}</button>
    <a
      href="https://instagram.com/blamang"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div>Instagram</div>
    </a>
    {/*<button onClick={() => setPage(Page.MY_STORY)}>{Page.MY_STORY}</button>*/}
    <button onClick={() => setPage(TopLevelPages.BREWS)}>{TopLevelPages.BREWS}</button>
    {/*<button onClick={() => setPage(TopLevelPages.VOID)}>{TopLevelPages.VOID}</button>*/}
    {/*<button onClick={() => setPage(TopLevelPages.GRAPH)}>{TopLevelPages.GRAPH}</button>*/}
  </div>
);

export default TopNav;
