import ImpatienceIPA from "./ImpatienceIPA/ImpatienceIPA";
import ImpatienceIPAThumbnail from "./ImpatienceIPA/assets/thumbnail_300-fs8.png";
import ConfluenceOfPollen from "./ConfluenceOfPollen/ConfluenceOfPollen";
import ConfluenceOfPollenThumbnail from "./ConfluenceOfPollen/assets/thumbnail_300-fs8.png";
import GreenApprentice from "./GreenApprentice/GreenApprentice";
import GreenApprenticeThumbnail from "./GreenApprentice/assets/thumbnail-300.png";
import PatientKnight from "./PatientKnight/PatientKnight";
import PatientKnightThumbnail from "./PatientKnight/assets/thumbnail-300.png";

/**
 * A data structure to hold standard information about a particular brew.
 * @author esyvret Aug. 8, 2021
 */
class Brew {
  constructor(key, name, Component, thumbnail) {
    /** The key of this brew in the brews map. */
    this.key = key;

    /** The human readable name of this brew. */
    this.name = name;

    /** The React component to display this brew's page. */
    this.Component = Component;

    /** The src for the thumbnail of this brew. */
    this.thumbnail = thumbnail;
  }
}

const BREWS = {
  ImpatienceIPA: new Brew( "ImpatienceIPA", "Impatience IPA", ImpatienceIPA, ImpatienceIPAThumbnail),
  ConfluenceOfPollen: new Brew("ConfluenceOfPollen", "Confluence of Pollen", ConfluenceOfPollen, ConfluenceOfPollenThumbnail ),
  GreenApprentice: new Brew("GreenApprentice", "Green Apprentice", GreenApprentice, GreenApprenticeThumbnail),
  PatientKnight: new Brew("PatientKnight", "Patient Knight", PatientKnight, PatientKnightThumbnail),
}

export default BREWS;