import React from 'react';
import './ImpatienceIPA.scss';
import labelSrc from "./assets/label_desktop-fs8.png";
import BREWS from "../Brews";

/**
 * The brew label page for the Impatience IPA.
 * @author esyvret Aug. 8, 2021
 */
const ImpatienceIPA = () => (
  <div className={'ImpatienceIPA brew'}>
    <div className={'fullpage'}>
      <img src={labelSrc} alt={BREWS.ImpatienceIPA.name} className={'label'} />
    </div>
  </div>
);

export default ImpatienceIPA;
