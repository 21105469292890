import React, {useContext} from 'react';
import LinkedImage from "./LinkedImage/LinkedImage";
import {getNode} from "../TheGraph";
import SpeechHistoryContext from "../context/SpeechHistoryContext";

/**
 * A page that displays the graph.
 * @author esyvret 2021-09-04
 */
const Exploration = () => {
  const {node, setNode} = useContext(SpeechHistoryContext);
  return (
    <div className={'Exploration-page'}>
      <p>{node.name}</p>
      <LinkedImage
        src={node.image}
        links={node.links}
        onTraversal={(link) => setNode(getNode(link.target))}
      />
    </div>
  )
};

export default Exploration;
