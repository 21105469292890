import BlobCoastSrc from './assets/blobs_ga_transparent.png';
import BlobCoastLinks from './assets/blobs_ga_transparent.png.links.json';
import ExplorationNode from "../../nodes/exploration/ExplorationNode";
import ExplorationLink from "../../nodes/exploration/ExplorationLink";

function generateBlobCoast() {
  new ExplorationNode(
    'blob coast',
    BlobCoastSrc,
    [
      new ExplorationLink(BlobCoastLinks[0].path, true, 'skyrim'),
      new ExplorationLink(BlobCoastLinks[1].path, true, 'start')
    ]);
}

export default generateBlobCoast;
