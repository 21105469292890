import React, {useContext} from 'react';
import {getNode} from "../TheGraph";
import NodeTypes from "../nodes/NodeTypes";
import SpeechHistoryContext from "../context/SpeechHistoryContext";
import './Speech.scss'

/**
 * A page that displays the graph.
 * @author esyvret 2021-09-04
 */
const Speech = () => {
  const { node, nodes, setNode } = useContext(SpeechHistoryContext);

  return (
    <div className={'speech-node'}>
      <div className={'speech-history'}>
        {
          nodes
            .filter(node => node.nodeType === NodeTypes.SPEECH
              || (node.nodeType === NodeTypes.SPEECH_OPTION && node.speech !== '>'))
            .map((node, index) =>
              <div className={`speech-history-line ${node.nodeType === NodeTypes.SPEECH_OPTION ? 'response' : ''}`}>
                <div className={`speech-history-text-container`}>
                  <p key={index} className={`speech-history-text`}>
                    {node.speech}
                  </p>
                </div>
              </div>
            )}
      </div>

      {node.options.map(option =>
        <button
          key={option.speech}
          className={'speech-option'}
          onClick={() => setNode([getNode(option.nodeName), option])}
        >
          {option.speech}
        </button>
      )}
    </div>
  )
};

export default Speech;
