import React from 'react';

import './GreenApprentice.scss';
import labelTopSpacerSrc from "./assets/label-top-spacer-fs8.png";
import labelTopBackgroundSrc from "./assets/label-top-background-fs8.png";
import illuminatedLetterSrc from "./assets/illuminated-letter-fs8.png";
import blobsSrc from "./assets/blobs_ga_transparent-fs8.png";
import labelBottomSrc from "./assets/label-bottom-fs8.png";

/**
 * The brew label page for Green Apprentice.
 * @author esyvret Aug. 8, 2021
 */
const GreenApprentice = () => (
  <div className={'GreenApprentice brew'}>
    <div className={'brew-content'}>
      <img src={labelTopBackgroundSrc} alt={''} className={'label-top-background'} />
      <img src={labelTopSpacerSrc} alt={''} className={'label-top-spacer'} />
      <div className={'brew-content-text'}>
        <h1>Green Apprentice</h1>
        <p>An India Pale Lager with the fruity-sweet flavour and aroma infusion of blue dream.</p>

        <h2>Recipe</h2>
        <ol>
          <li>Water</li>
          <li>Dried dual pod germinated cereal grain (aka&nbsp;2-Row)</li>
          <li>Saaz</li>
          <li>Blue Dream</li>
          <li>Saflager S-23</li>
        </ol>

        <h2>Ingredients</h2>
        <p>Water, Malted Barley, Hops, Cannabis, Yeast</p>
        <p>
          500 ml<br/>
          5-6% alc./vol.<br/>
          ?% THC/vol. *<br/>
          Beer<br/>
        </p>

        <p>* We have not noticed any psychoactive effects from the cannabis in this beer.</p>
        <hr/>
        <p>Consume at your own risk. This home brew is brought to you for free by the Secret Algorithms™ brewery. Feedback and questions are welcome, appreciated, and can be directed to info.secretab@gmail.com</p>
        <p>Please rinse bottle with plain water and return at your convenience.</p>
        <img src={blobsSrc} alt={''} className={'blobs'} />
        <p className={'story'}>
          <a href={'/green_apprentice_map.png'}><img src={illuminatedLetterSrc} alt={'O'} className={'illuminated-letter'} /></a>
          n the seam of rolling hills, sparse winding roads, the glimmer of reflective familiarity.
          Formations of fibrous structural tissue in a scattered arrangement, impassive to the sprightly developing
          sky. The green apprentice toils relentlessly, limbs of consciousness woven into the deepsoil as their
          masters thrive on their generosity. Depthful wellspring of potential bound and untapped until the right
          moment, when they choose to be released. Leaves of ingenuity catching the winds of change, still green to
          the ways of their immersion but requiring masters no longer. Green with eagerness, their craft shaping to
          the mechanism of advancement incrementally. One misstep is no burden to the journey, but a foothold to
          press onward.
        </p>
        <p>"Move on before they take your last will to move any further."</p>
      </div>
      <img src={labelBottomSrc} alt={''} className={'label-bottom-spacer'} />
      <img src={labelBottomSrc} alt={''} className={'label-bottom'} />
    </div>
  </div>
);

export default GreenApprentice;
