/**
 * The graph.
 * Wait, it's all a Map? Always has been.
 * I wonder how long it will be before I start to regret making this effectively global...
 * @author esyvret 2021-09-04
 */
const TheGraph = new Map();

/** Adds a newly created node to the graph. If the node is already present then it returns the old copy instead. */
const addNode = (node) => {
  const oldNode = TheGraph[node.name];
  if (oldNode) {
    console.log("duplicate node creation detected, sending OG node in its place");
    return oldNode;
  }
  else {
    TheGraph[node.name] = node;
    return node;
  }
}

// /**
//  * Get a function that should be used to construct a node class. Adds the newly constructed node to the TheGraph before
//  * returning it.
//  */
// const getNodeCreator = (NodeClass) => {
//   return (...args) => addNode(new NodeClass(...args));
// }

/** Fetch the node by its name from the graph. */
const getNode = name => TheGraph[name];

export { addNode, getNode }
