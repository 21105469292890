import './App.scss';
import './burger.css';
import React, {useState, useCallback, useEffect} from 'react';
import StyledBackground from "./StyledBackground";
import ImageImporter from "./ImageImporter";
import Gallery from "./gallery/Gallery";
import galleryImages from "./gallery/galleryImages";
import BrewsPage from "./pages/BrewsPage";
import BREWS from "./brews/Brews";
import GraphPage from "./pages/GraphPage";
import generateTheGraph from "./graph/graph/GraphGenerator";
import TopLevelPages from "./topnav/TopLevelPages";
import TopNav from "./topnav/TopNav";
import SpeechHistoryProvider from "./graph/context/SpeechHistoryProvider";

generateTheGraph();

const Styles = {
  NONE: '-fs8.png',
  STARRY: '-starry.jpg',
  CANDY: '-candy.jpg',
  MOSAIC: '-mosaic.jpg',
};

const Images = {
  HOME_BACKGROUND: {
    src: 'HomeBackground',
    links: [
      {x1: 0, x2: 0.3, y1: 0, y2: 1, target: Styles.NONE},
      {x1: 0.3, x2: 0.6, y1: 0, y2: 1, target: Styles.CANDY},
      {x1: 0.6, x2: 1, y1: 0, y2: 1, target: Styles.MOSAIC},
    ]
  },
  DJINN1: {
    src: 'djinn1',
    links: [
      {x1: 0.3875, x2: 0.4264, y1: 0.4744, y2: 0.5116, getTarget: () => Images.DJINN2},
      // { x1: 0.3, x2: 0.6, y1: 0, y2: 1, target: Styles.CANDY},
      // { x1: 0.6, x2: 1, y1: 0, y2: 1, target: Styles.MOSAIC},
    ]
  },
  DJINN2: {
    src: 'djinn2',
    links: [
      {x1: 0, x2: 0.3, y1: 0, y2: 1, getTarget: () => Images.DJINN1},
      // { x1: 0.3, x2: 0.6, y1: 0, y2: 1, target: Styles.CANDY},
      // { x1: 0.6, x2: 1, y1: 0, y2: 1, target: Styles.MOSAIC},
    ]
  },
}

const useNodeListener = (node, eventName, handler) => {
  useEffect(() => {
    node.addEventListener(eventName, handler);
    return () => node.removeEventListener(eventName, handler);
  })
}

function App() {
  const params = new URLSearchParams(window.location.search);
  const [page, setPageWithoutPushingHistory] = useState(params.get("page") || TopLevelPages.GALLERY);
  // const [style, setStyle] = useState(Styles.NONE);
  const style = Styles.NONE;

  const setPage = useCallback(name => {
    setPageWithoutPushingHistory(name);
    window.scrollTo(0,0);
    window.history.pushState({page: name}, name, `/?page=${name}`);
  }, [])

  // const styleName = Object.keys(Styles).find(styleName => Styles[styleName] === style).toLowerCase();

  useNodeListener(window, 'popstate', useCallback((e) => {
    if (e.state) {
      setPageWithoutPushingHistory(e.state.page)
    }
  }, []))

  useEffect(() => {
    window.history.replaceState({page}, page, `/?page=${page}`);
    // eslint-disable-next-line
  }, [])

  let content;
  switch (page) {
    case TopLevelPages.HOME:
      content = (
        <>
          <StyledBackground src={Images.HOME_BACKGROUND.src} style={style}/>
          <div className="content centered">
            <h1>{page}</h1>
            <p>
              This is the home page. There's not much here yet, but great things are ahead!
            </p>
          </div>
        </>
      );
      break;
    case TopLevelPages.VOID:
      content = (
        <>
          <StyledBackground src={Images.HOME_BACKGROUND.src} style={style}/>
          <ImageImporter/>
        </>
      );
      break;
    case TopLevelPages.GALLERY:
      content = (
        <Gallery
          srcSets={galleryImages}
          style={style}
        />
      )
      break;
    case TopLevelPages.BREWS:
      content = (
        <BrewsPage setBrew={setPage} />
      )
      break;
    case TopLevelPages.GRAPH:
      content = <GraphPage />;
      break;
    default:
      const Brew = BREWS[page];
      content = Brew ? <Brew.Component /> : (
        <>
          <StyledBackground src={Images.HOME_BACKGROUND.src} style={style}/>
          <p className="content centered">Woah dude, what happened? Something broke?</p>
        </>
      );
      break;
  }

  return (
    <SpeechHistoryProvider>
      <div className="App">
        <TopNav setPage={setPage} />
        <div>
          {content}
        </div>
      </div>
    </SpeechHistoryProvider>
  );
}

export default App;
