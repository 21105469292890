import React from 'react';
import labelSrc from "./assets/label-fs8.png";
import BREWS from "../Brews";

import '../Brews.scss';
import './ConfluenceOfPollen.scss';

/**
 * The brew label page for the Impatience IPA.
 * @author esyvret Aug. 8, 2021
 */
const ConfluenceOfPollen = () => (
  <div className={'ConfluenceOfPollen brew'}>
    <div className={'fullpage'}>
      <img src={labelSrc} alt={BREWS.ConfluenceOfPollen.name} className={'label'} />
    </div>
  </div>
);

export default ConfluenceOfPollen;
