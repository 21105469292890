import React, {useEffect, useState} from 'react';

let nextUniqueId = 0;
const getUniqueId = () => ++nextUniqueId;

// the attempted framerate
const FRAMERATE = 60;

// the approximate minimum duration of the animation in seconds
const DURATION = 0.1;

/** Animated rhombus image tile for the gallery page. */
const ImageTile = ({src, srcSet, alt, className, marginScaleFactor }) => {
  const [clipPathId] = useState(() => `clipPath${getUniqueId()}`);
  const [isExpanding, setIsExpanding] = useState(false);
  const [expandedness, setExpandedness] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (isExpanding && expandedness < 1) {
        setExpandedness(e => Math.min(1, e + 1 / DURATION / FRAMERATE));
      }
      if (!isExpanding && expandedness > 0) {
        setExpandedness(e => Math.max(0, e - 1 / DURATION / FRAMERATE));
      }
    }, 1000 / FRAMERATE);
  });

  // make the border size based on the margin size
  const borderThickness = 1/marginScaleFactor;

  const clippingPathDefinition =
    `M ${1+expandedness} 0 L 2 ${1+expandedness} L ${1-expandedness} 2 L 0 ${1-expandedness} Z`;

  return (
    <svg
      viewBox={`${
        -borderThickness
      } ${
        -borderThickness
      } ${
        2 + 2*borderThickness
      } ${
        2 + 2*borderThickness
      }`}
      onMouseEnter={() => setIsExpanding(true)}
      onMouseLeave={() => setIsExpanding(false)}
      onTouchEnd={() => setIsExpanding((prev) => !prev)}
      className={className}
    >
      <clipPath id={clipPathId}>
        <path d={clippingPathDefinition}/>
      </clipPath>

      <path
        d={clippingPathDefinition}
        stroke={'black'}
        strokeLinejoin={'round'}
        strokeLinecap={'round'}
        strokeWidth={borderThickness}
        opacity={expandedness}
      />
      <image
        clipPath={`url(#${clipPathId})`}
        href={srcSet ? srcSet['1x'] || src : src}
        x={0}
        y={0}
        width={2}
        height={2}
        // srcSet={srcSet}
      />
    </svg>


  );
};

export default ImageTile;
