import React, {useCallback, useEffect, useRef, useState} from 'react';
import LinkedImage from "./graph/components/LinkedImage/LinkedImage";

const useNodeListener = (node, eventName, callback) => useEffect(() => {
  node.addEventListener(eventName, callback);
  return () => node.removeEventListener(eventName, callback);
}, [node, eventName, callback]);

// const useRefListener = (ref, eventName, callback) => useEffect(() => {
//     const node = ref.current;
//     node.addEventListener(eventName, callback);
//     return () => node.removeEventListener(eventName, callback);
// }, [ref, eventName, callback]);

const ImageImporter = () => {
  const dropZoneRef = useRef(null);
  const [imageName, setImageName] = useState('');
  const [imageSrc, setImageSrc] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const onDrop = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const reader = new FileReader();
      reader.onload = e => setImageSrc(e.target.result);
      reader.readAsDataURL(file);
      setImageName(file.name);
    }
    setIsDragging(false);
  }, []);

  const onDragOver = useCallback((e) => {
    setIsDragging(true);
    e.preventDefault();
  }, []);

  useNodeListener(document, 'dragover', onDragOver);

  return (
    <>
      {
        isDragging
          ? <div
            ref={dropZoneRef}
            className={'image-importer-drop-zone'}
            onDrop={onDrop}
            onDragOver={onDragOver}
          />
          : null
      }
      {imageSrc ? (
        <div className="content centered">
          <LinkedImage name={imageName} src={imageSrc} isEditing={false}/>
        </div>
      ) : null}
    </>
  );
};

export default ImageImporter;