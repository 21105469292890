/**
 * A node which defines a physical location that the player can be. It has an image of the location and click zones for
 * navigating to other nodes. Sometimes the image doesn't exactly represent the character's precise location, just the
 * general area that they have access to (e.g. nodes that display a map of an area).
 */
class ExplorationLink {
  constructor(path, lit, target) {

    /**
     * A polygonal path which defines the physical dimensions and limits of the link on the image where it is displayed.
     */
    this.path = path;

    /** Specifies whether this polygon should appear lit up. */
    this.lit = lit;

    /** The node to navigate to when this exploration link is used. */
    this.target = target;
  }
}

export default ExplorationLink;
