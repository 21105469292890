import arm_1126 from './images/arm_alg_1126.webp';
import arm_2252 from './images/arm_alg_2252.webp';
import arm_563 from './images/arm_alg_563.webp';
import being_1126 from './images/being_alg_1126.webp';
import being_2252 from './images/being_alg_2252.webp';
import being_563 from './images/being_alg_563.webp';
import blue_1126 from './images/blue_alg_1126.webp';
import blue_2252 from './images/blue_alg_2252.webp';
import blue_563 from './images/blue_alg_563.webp';
import brain_1126 from './images/brain_alg_1126.webp';
import brain_2252 from './images/brain_alg_2252.webp';
import brain_563 from './images/brain_alg_563.webp';
import bulb_1126 from './images/bulb_alg_1126.webp';
import bulb_2252 from './images/bulb_alg_2252.webp';
import bulb_563 from './images/bulb_alg_563.webp';
import chief_1126 from './images/chief_alg_1126.webp';
import chief_2252 from './images/chief_alg_2252.webp';
import chief_563 from './images/chief_alg_563.webp';
import conc_1126 from './images/conc_alg_1126.webp';
import conc_2252 from './images/conc_alg_2252.webp';
import conc_563 from './images/conc_alg_563.webp';
import nester_1126 from './images/nester_alg_1126.webp';
import nester_2252 from './images/nester_alg_2252.webp';
import nester_563 from './images/nester_alg_563.webp';
import remains_1126 from './images/remains_alg_1126.webp';
import remains_2252 from './images/remains_alg_2252.webp';
import remains_563 from './images/remains_alg_563.webp';
import resting_1126 from './images/resting_alg_1126.webp';
import resting_2252 from './images/resting_alg_2252.webp';
import resting_563 from './images/resting_alg_563.webp';
import square_eye_1126 from './images/square_eye_alg_1126.webp';
import square_eye_2252 from './images/square_eye_alg_2252.webp';
import square_eye_563 from './images/square_eye_alg_563.webp';
import wyrm_1126 from './images/wyrm_alg_1126.webp';
import wyrm_2252 from './images/wyrm_alg_2252.webp';
import wyrm_563 from './images/wyrm_alg_563.webp';

// To compress to webp do
// cwebp -lossless -z 8 wyrm_alg_563.png -o wyrm_alg_563.webp

const galleryImages = [
  { '4x': being_2252, '2x': being_1126, '1x': being_563 },
  { '4x': blue_2252, '2x': blue_1126, '1x': blue_563 },
  { '4x': brain_2252, '2x': brain_1126, '1x': brain_563 },
  { '4x': bulb_2252, '2x': bulb_1126, '1x': bulb_563 },
  { '4x': chief_2252, '2x': chief_1126, '1x': chief_563 },
  { '4x': arm_2252, '2x': arm_1126, '1x': arm_563 },
  { '4x': conc_2252, '2x': conc_1126, '1x': conc_563 },
  { '4x': nester_2252, '2x': nester_1126, '1x': nester_563 },
  { '4x': remains_2252, '2x': remains_1126, '1x': remains_563 },
  { '4x': resting_2252, '2x': resting_1126, '1x': resting_563 },
  { '4x': square_eye_2252, '2x': square_eye_1126, '1x': square_eye_563 },
  { '4x': wyrm_2252, '2x': wyrm_1126, '1x': wyrm_563 },
];

// TODO implement this?
// const getGalleryImageProvider = ({large, medium, small}) => {};

export default galleryImages;
