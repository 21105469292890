import SpeechHistoryContext from "./SpeechHistoryContext";
import {useReducer} from "react";
import {getNode} from "../TheGraph";


const SpeechHistoryProvider = ({ children }) => {
  const [nodes, setNode] = useReducer(
    (prev, next) => Array.isArray(next) ? [...next, ...prev] : [next, ...prev],
    [getNode("start")]);
  const [node] = nodes;
  return (
    <SpeechHistoryContext.Provider value={{ node, nodes, setNode }}>
      {children}
    </SpeechHistoryContext.Provider>
  );
}

export default SpeechHistoryProvider;
