import pointInPolygon from 'point-in-polygon';

/** Utils for manipulating link polygon paths. */
const pointAsArray = point => [point.x, point.y];
const flattenPathPoints = path => path.map(pointAsArray).flat();
const pathToString = path => flattenPathPoints(path).join(' ');

export {
  pointAsArray,
  flattenPathPoints,
  pathToString,
  pointInPolygon
};