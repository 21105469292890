import React, {useCallback, useState} from "react";
import getMouseCoordsOnTarget from "../../../utils/getMouseCoordsOnTarget";
import {flattenPathPoints, pointAsArray, pointInPolygon} from "./PathUtils";
import ImageWithVisibleLinkZones from "./ImageWithVisibleLinkZones";

const SimpleLinkedImage = ({src, alt, links = [], onTraversal = () => {}}) => {
  const [focusedLinkIndex, setFocusedLinkIndex] = useState(-1);

  /** Gets the index of the link that is targeted by a mouse event. */
  const getTargetLinkIndex = useCallback((e) => {
    const mouse = getMouseCoordsOnTarget(e);
    return links.findIndex(link => pointInPolygon(pointAsArray(mouse), flattenPathPoints(link.path)));
  }, [links]);

  const onPointerUp = useCallback((e) => {
    const targetLinkIndex = getTargetLinkIndex(e);
    setFocusedLinkIndex(targetLinkIndex);
    onTraversal(links[targetLinkIndex])
  }, [links, onTraversal, getTargetLinkIndex]);

  const onPointerMove = useCallback((e) => {
    setFocusedLinkIndex(getTargetLinkIndex(e));
  }, [getTargetLinkIndex]);

  return (
    <ImageWithVisibleLinkZones
      src={src}
      alt={alt}
      links={focusedLinkIndex === -1 ? [] : [links[focusedLinkIndex]]}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
    />
  );
}

export default SimpleLinkedImage;