import React, {useCallback, useEffect, useReducer, useRef} from 'react';
import getMouseCoordsOnTarget from "../../../utils/getMouseCoordsOnTarget";
import ImageWithVisibleLinkZones from "./ImageWithVisibleLinkZones";
import {flattenPathPoints, pointAsArray, pointInPolygon} from "./PathUtils";

const ACTIONS = {
  ADD_TO_PATH: 'ADD_TO_PATH',
  DELETE: 'DELETE',
  END_PATH: 'END_PATH',
  START_PATH: 'START_PATH',
}

const ImportedImageForLinking = ({
  name,
  src,
  initialLinks = [],
  onEdit = () => {}
}) => {
  const [state, setState] = useReducer((state, mod) => {
    const newState = {links: [...state.links], currentPath: state.currentPath};
    switch (mod.action) {
      case ACTIONS.END_PATH:
        if (newState.currentPath.length > 0) {
          newState.links.push({path: newState.currentPath})
          newState.currentPath = [];
        }
        break;
      case ACTIONS.START_PATH:
        break;
      case ACTIONS.ADD_TO_PATH:
        newState.currentPath = newState.currentPath.concat([mod.point]);
        break;
      case ACTIONS.DELETE:
        newState.links.splice(newState.links.indexOf(mod.link), 1);
        break;
      default:
        throw new Error("Unrecognized action in ImportedImageForLinking.");
    }
    return newState;
  }, {links: [...initialLinks], currentPath: []}, () => ({links: [...initialLinks], currentPath: []}));

  useEffect(() => onEdit(state.links), [onEdit, state.links]);

  const onPointerDown = useCallback((e) => {
    const mouse = getMouseCoordsOnTarget(e);
    const link = state.links.find(link => pointInPolygon(pointAsArray(mouse), flattenPathPoints(link.path)));
    if (link) {
      setState({action: ACTIONS.DELETE, link});
    } else {
      setState({action: ACTIONS.START_PATH, link});
    }
  }, [state]);

  const onPointerUp = useCallback((e) => {
    setState({action: ACTIONS.END_PATH});
  }, []);

  const onPointerMove = useCallback((e) => {
    if (e.touches || e.buttons) {
      const mouse = getMouseCoordsOnTarget(e);
      setState({action: ACTIONS.ADD_TO_PATH, point: mouse});
    }
  }, []);

  useEffect(() => console.log(state.links), [state.links]);

  const ref = useRef(null);
  const onDownload = (e) => {
    const blob = new Blob([JSON.stringify(state.links)], {type: 'text/plain'});
    const a = document.createElement('a');
    a.download = `${name}.links.js`;
    a.href = window.URL.createObjectURL(blob);
    ref.current.appendChild(a);
    a.click();
    ref.current.removeChild(a);
  }

  return (
    <div ref={ref}>
      <ImageWithVisibleLinkZones
        src={src}
        links={state.links}
        currentPath={state.currentPath}
        onPointerMove={onPointerMove}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
      />
      <button onClick={onDownload}>Download Image Link File</button>
    </div>
  );
};

export default ImportedImageForLinking;
