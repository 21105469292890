import React, {useState} from "react";
import {pathToString} from "./PathUtils";

const scaleUnitToBox = (path, width, height) => {
  return path.map(({x, y}) =>  ({ x: x*width, y: y*height}));
};

const ImageWithVisibleLinkZones = ({ src, alt, links, currentPath, onPointerMove, onPointerDown, onPointerUp }) => {
  const [{width, height}, setDimensions] = useState({width: 0, height: 0});
  const onLoad = (e) => {
    const { offsetWidth: width, offsetHeight: height } = e.target;
    setDimensions({width, height});
  }

  return (
    <div
      className="linked-image"
      onPointerMove={onPointerMove}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
    >
      <img src={src} alt={alt} onLoad={onLoad} draggable={false}/>
      <svg
        className="highlighted-path"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="none"
      >
        {
          // display the clickable paths
          links.map((link, index) => (
            <path key={index} strokeWidth={2} stroke={"#FFFF00"} fill={"#FFFF0066"}
                  d={`M ${pathToString(scaleUnitToBox(link.path, width, height))} Z`}/>
          ))
        }
        {
          // display the path that is currently being edited if there is one
          currentPath
            ? <path strokeWidth={2} stroke={"#FFFF00"} fill={"#FFFF0066"} d={`M ${pathToString(scaleUnitToBox(currentPath, width, height))}`}/>
            : null
        }
      </svg>
    </div>
  );
}

export default ImageWithVisibleLinkZones;
