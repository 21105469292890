import NodeTypes from "../NodeTypes";

/** A class that defines a response option in a dialogue. */
class SpeechOption {
  constructor(speech, nodeName) {

    /** The words that the player will say if they select this option. */
    this.speech = speech;

    /** The name of the node that the player will visit if they select this option. */
    this.nodeName = nodeName;

    /** The node's type. */
    this.nodeType = NodeTypes.SPEECH_OPTION;
  }
}

export default SpeechOption;
