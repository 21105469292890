import {addNode} from "../../TheGraph";
import NodeTypes from "../NodeTypes";

/** A node which defines a section of dialogue in which one character says something to someone. Has response options. */
class SpeechNode {
  constructor(name, image, speech, options) {

    /** The name of the node. */
    this.name = name;

    /** The portrait of whoever or whatever is talking (or the item being described in narration). */
    this.image = image;

    /** The words that are being said. */
    this.speech = speech;

    /** The player's response options. Should be of type SpeechOption. */
    this.options = options;

    /** The node's type. */
    this.nodeType = NodeTypes.SPEECH;

    /** Adds the node to the graph. How long until I regret this decision? */
    return addNode(this);
  }
}
export default SpeechNode;
