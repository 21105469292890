import {addNode} from "../../TheGraph";
import NodeTypes from "../NodeTypes";

/**
 * A node which defines a physical location that the player can be. It has an image of the location and click zones for
 * navigating to other nodes. Sometimes the image doesn't exactly represent the character's precise location, just the
 * general area that they have access to (e.g. nodes that display a map of an area).
 */
class ExplorationNode {
  constructor(name, image, links) {

    /** The name of the node. */
    this.name = name;

    /** The image of the area represented by this node. */
    this.image = image;

    /** The links to other nodes to display on the image. An array of ExplorationLinks. */
    this.links = links;

    /** The node's type. */
    this.nodeType = NodeTypes.EXPLORATION;

    /** Adds the node to the graph. How long until I regret this decision? */
    return addNode(this);
  }
}

export default ExplorationNode;
