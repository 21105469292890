import SpeechOption from "../nodes/dialogue/SpeechOption";
import SpeechNode from "../nodes/dialogue/SpeechNode";
import createSpeechNodes from "../nodes/dialogue/createSpeechNodes";
import generateBlobCoast from "./BlobCoast/BlobCoast";

function generateTheGraph() {
  new SpeechNode(
    'start',
    null,
    'The Graph',
    [new SpeechOption('Start Game', 'skyrim'),
      new SpeechOption('I wanna go to the blob coast.', 'blob coast')]
  );
  createSpeechNodes(
    'skyrim',
    null,
    [
      'Hey, you.',
      'You’re finally awake.',
      'You were trying to cross the border, right? Walked right into that Imperial ambush, same as us, and that thief over there.',
    ],
    [
      new SpeechOption('Fus Roh Dah, you lollygagging, sweetroll-stealing, skooma-swallowing milk-drinker!', 'do you even lift'),
      new SpeechOption('I hear you\'ve got curved swords. Curved. Swords.', 'hah gae')
    ])
  createSpeechNodes(
    'do you even lift',
    null,
    ['This was just a test, jeez, no need to be so mean.', 'Do you even lift?'],
    [new SpeechOption('Yeah, of course I lift.', 'too bad')]
  );
  new SpeechNode('too bad', null, 'Well, too bad. You go to the blob coast for your crimes against skyrim and her people.', [new SpeechOption('Oh no! Anyways...', 'blob coast')]);
  new SpeechNode('hah gae', null, 'Hah, gae!', [new SpeechOption('idk this reference yet', 'too bad')]);

  generateBlobCoast();
}

export default generateTheGraph;
