import React, {useContext} from 'react';
import Speech from "../graph/components/Speech";
import Exploration from "../graph/components/Exploration";
import NodeTypes from "../graph/nodes/NodeTypes";

import './GraphPage.scss';
import SpeechHistoryContext from "../graph/context/SpeechHistoryContext";

/**
 * A page that displays the graph.
 * @author esyvret 2021-09-04
 */
const GraphPage = () => {
  const { node } = useContext(SpeechHistoryContext);
  let content = null;

  switch(node.nodeType) {
    case NodeTypes.SPEECH:
      content = <Speech />;
      break;
    case NodeTypes.EXPLORATION:
      content = <Exploration />;
      break;
    default:
      // display nothing for unknown node type
      break;
  }

  return (
    <div className={'graph-page'}>
      <div className={'fullpage'}>
        <div className={'content'} tabIndex={0}>
          {content}
        </div>
      </div>
    </div>
  )
};

export default GraphPage;
